html body {
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  /*background: rgb(222,20,20);
  background: radial-gradient(circle, rgba(222,20,20,1) 0%, rgba(21,21,21,1) 100%);*/
  background: url('../assets/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #151515;  
}

@media screen and (min-width: 1200px) {
  html body { background-size: cover; } 
}

@media screen and (max-width: 640px) {
  html body { background-position: top; } 
}

div.splash {
  position: relative;
  background: url('../assets/background.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #151515;
}


/* FONTS */

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans EBI";
  src: url("../fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans BI";
  src: url("../fonts/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans EB";
  src: url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "BullDozer";
  src: url("../fonts/Bulldozer.ttf") format("truetype");
}


/* Fix taille typo smartphone */
@media screen and (max-width: 640px) {
  p { font-size: 16px; }
}

/* HEADINGS */

h1 { font-family: "Bulldozer"; }

h1.title {
  font-size: 4rem;
  text-align: center;
}

h1.team-member-name {
  font-size: 2.5rem!important;
  color: #CD2924;
  margin-top: 10px;
}

/* GENERAL */

a {
  color: #FFF!important;
  text-decoration: none!important;
}

a:hover { color: #CD2924!important; }

/* HEADER */

div.header-container {
  background-color: #151515;
}

header .nav ul.bb-pages li {
  font-family: "Open Sans BI", Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 0 10px;
  letter-spacing: 2px;
}

header .nav ul li a {
  color: #FFF;
  display: block; 
  text-decoration: none;
  padding: 8px 0;
}

.transparent-menu { background: none!important; }

.transparent-menu header .nav ul li a:hover, .transparent-menu header .nav ul li a:active, .transparent-menu header .nav ul li a.active { color: #151515!important; }
header .nav ul li a:hover, header .nav ul li a:active, header .nav ul li a.active { color: #CD2924!important; }


@media screen and (min-width: 992px) {

  header .nav ul.bb-pages li { margin: 0 20px; }
  header .nav ul.bb-sn { margin-left: 20px; }

}

header .nav ul.bb-sn li a img { border: none; }
header .nav ul li a.disabled { color: #AAA!important; }


/* Social Networks */

header div.sn-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
}

header div.twitter-icon { background:transparent url('../assets/sn-twitter-24.png') center top no-repeat; }
header div.twitter-icon:hover { background:transparent url('../assets/sn-twitter-black-24.png') center top no-repeat; }

header div.discord-icon { background:transparent url('../assets/sn-discord-24.png') center top no-repeat; }
header div.discord-icon:hover { background:transparent url('../assets/sn-discord-black-24.png') center top no-repeat; }

header div.opensea-icon { background:transparent url('../assets/sn-opensea-24.png') center top no-repeat; }
header div.opensea-icon:hover { background:transparent url('../assets/sn-opensea-black-24.png') center top no-repeat; }

/* HOME PAGE */

div#banner { height: 100vh; }

h1.homepage-title {
  font-family: "Open Sans EBI", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-shadow: 0px 0px 5px #000;
  font-size: 5rem!important;
}

h1.homepage-subtitle {
  font-family: "Open Sans EBI", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #0F0F0F;
  text-shadow: 0px 0px 5px #FFF;
  font-size: 5rem!important;

}

.white-button {
  font-family: "Open Sans EB", Arial, Helvetica, sans-serif!important;
  border: 1px solid #151515!important;
  border-radius: 30px!important;
  min-width: 320px;
  color: #151515!important;
  font-size: 1.5rem!important;
  background: linear-gradient(to bottom, #fff 0%, #ccc 100%);
  text-transform: uppercase;
}

img#homepage-logo {
  width: 320px;
  margin: 0 auto;
  display: block
}


@media screen and (min-width: 2000px) { div#banner { height: 75vh; } }

@media screen and (max-width: 576px) {
  div#banner {
    height: 50vh;
    background-size: cover;
  }

  h1.homepage-title { font-size: 3rem!important; }
  h1.homepage-subtitle { font-size: 2rem!important; }
  .white-button {  font-size: 1.2rem!important; }
}

/* Specs */

div#collection h1 {
  color: #ea1412;
  font-size: 6rem;
}

div.spec {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "Open Sans BI", Arial, Helvetica, sans-serif;
  text-align: right;
  font-size: 1.6rem;
  padding: 20px;
  background: url('../assets/spec2.png') no-repeat, #8e1819;
  background-size: 75px;
  background-position-y: 2px;
  background-position-x: 20px;
}

div.spec-alt {
  background: url('../assets/spec.png') no-repeat, #000;
  background-size: 75px;
  background-position-y: 2px;
  background-position-x: calc(100% - 20px);
  text-align: left;
}

@media screen and (max-width: 576px) {
  div.spec {
    font-size: 1rem;
    text-align: center;
    background-image: none;
  }  
}

/* Sneak Peek */

div#sneak-peek {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-w {
  float: left;
  animation: w ease-in-out 1.5s infinite alternate;
  transform-origin: center -36px;
}

@media screen and (max-width: 576px) { .sp-w  { margin: 30px; } }

.sp-w2 {
  animation: w2 ease-in-out 1.5s infinite alternate;
}

.sp-w:hover { animation-play-state: paused; }

.sp-w img {
  border: 5px solid #f8f8f8;
  display: block;
  width: 280px;
  height: 280px;
}

.sp-w:after{
  content: '';
  position: absolute;
  width: 20px; 
  height: 20px;
  border: 1.5px solid #ffffff;
  top: -8px; 
  left: 50%;
  border-bottom: none;
  border-right: none;
  transform: rotate(35deg);
}
       
.sp-w:before{
  content: '';
  position: absolute;
  top: -23px;
  left: 50%;
  display: block;
  height: 44px;
  width: 47px;
  background-image: url('../assets/red-btn.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  z-index: 16;
}
        
@keyframes w {
  0% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
}

@keyframes w2 {
  0% { transform: rotate(-5deg); }
  100% { transform: rotate(5deg); }
}

/* Team */

div.team-member-bg {
    width: 300px;
    height: 320px;
    border-radius: 50%;
    border: 4px solid #B12723;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    filter:grayscale(100%);
    background-image: url('../assets/team-members.jpg');
    background-position-x: 0;
    background-position-y: -5px;
}

@media screen and (max-width: 576px) { div.team-member-bg { filter:grayscale(0%); } }

div.team-member-bg:hover { filter:grayscale(0%); background-image: url('../assets/team-members-alt.jpg'); }

div.tm-1 { background-position-x: -15px; }
div.tm-2 { background-position-x: -335px; }
div.tm-3 { background-position-x: -655px; }
div.tm-4 { background-position-x: -975px; }

/* MINT PAGE */

.drop-shadow { filter:drop-shadow(0px 5px 15px black); }

div.card {
  position: relative;
  text-align: center;
  color: black;
  border: 1px solid black;
  background-color: black;
  transition: all .2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-index: 2;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

div.ineligible {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

div.ineligible::before {
  content: "Inéligible";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: white;
  font-size: 40px;
  z-index: 2;
  opacity: 0.3;
}

div.card-body {
  border: 1px solid #151515;
  background-color: white;
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

div.card-title { padding: 5px; font-size: 1.6rem; background-color: #8f181a; color: white; border-radius: 5px; }

div.card-footer {
  color: #d9bc3c;
  font-style: italic;
  font-size: 1rem;
}

.btn-group-sm .btn { min-width: 28px; font-size: 12px; }
.no-cursor { cursor: default!important; }



/* FOOTER */

#footer-block {
  padding: 10px 0 5px 0;
  border-radius: 15px;
  text-shadow: #151515 1px 0 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 576px) { #footer-block { border-radius: 0; } }

footer h3 {
  font-family: "BullDozer";
  font-size: 40px;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) { footer h3 { font-size: 25px; } }

span.sc { text-transform: none; font-size: 10px; }
span.made-with-love { text-transform: none; font-size: 10px; }

/* Connect Zone */

div.connect-zone button { margin: 0 auto!important; transform: none!important; }

/* Tracking des phases */

div#steps-timeline { font-size: 18px; }

div#steps-timeline span { margin-left: 10px; }

div#steps-timeline span:not(:last-child):after {
  content:'\203A';
  margin-left: 10px;
  color: #ccc;
  text-decoration: none;
  display: inline-block;
}

div#steps-timeline span.previous { text-decoration: line-through; }
div#steps-timeline span.active {
  color: black;
  font-weight: bold;
  text-shadow: 0px 0px 5px #e61a1b;
}